import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import markupSchema from "../config/markupSchemas.json";

import "./styles/Achievements.css";

import achievements from "../images/png/background/achievements_background.png";
import duoDate from "../images/png/achievements/duoDate.png";
import easySchool from "../images/png/achievements/easySchool.png";
import airbus from "../images/png/achievements/airbus.png";
import etiopathe from "../images/png/achievements/etiopathe.png";
import leou from "../images/png/achievements/leou.png";
import velofute from "../images/png/achievements/velofute.png";

const Achievements = () => {
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);
    const organizationSchema = markupSchema.services;

    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".achievements");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    //Render
    return (
        <main className={`achievements ${darkMode ? "dark" : "light"}`} onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("services.metaTitle")}</title>
                <meta name="description" content={t("services.metaContent")} />
                <meta property="og:title" content="Services Complets pour Votre Entreprise | Digital Agency" />
                <meta property="og:description" content="Découvrez nos services spécialisés : stratégie marketing, transformation numérique, design UX/UI, SEO, SEA, et bien plus. Optimisez et transformez votre entreprise avec nos solutions sur mesure." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dreameragency.fr/services/" />
                <meta property="og:image" content="https://dreameragency.fr/media/OG/agency_background.png" />
            </Helmet>
            <section className="achievements-section1">
                <img src={achievements} alt="Fond de Page" className="achievements-agency-background" loading="eager" />
                <div className={`achievements-introduction ${darkMode ? "dark" : "light"}`}>
                    <h1 className="achievements-introduction-title">{t("achievements.introduction-title")}</h1>
                    <p className="achievements-introduction-body">
                        {t("achievements.introduction-body").split("\n").map((line, i, arr) => (
                            <React.Fragment key={i}>
                                {line}
                                {i !== arr.length -1 && <br />}
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            </section>

            <section className="achievements-section2">
                <div className="achievements-section2-line">
                    <Link to="/achievements/duoDate" className="achievements-container">
                        <img src={duoDate} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                    <Link to="/achievements/easySchool" className="achievements-container">
                        <img src={easySchool} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                    <Link to="/achievements/airbus" className="achievements-container">
                        <img src={airbus} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                </div>
                <div className="achievements-section2-line">
                    <Link to="/achievements/etiopathe" className="achievements-container">
                        <img src={etiopathe} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                    <Link to="/achievements/leou" className="achievements-container">
                        <img src={leou} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                    <Link to="/achievements/velofute" className="achievements-container">
                        <img src={velofute} alt="Service de Stratégie Marketing" className="achievements-image" loading="lazy" />
                    </Link>
                </div>                
            </section>

            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </main>
    );
}

export default Achievements;