import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import pageAchievementData from "../config/pageAchievementData";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { Helmet } from "react-helmet-async";

import "./styles/AchievementGenericPage.css";

const AchievementGenericPage = () => {
    const { pageId } = useParams();
    const data = pageAchievementData[pageId];
    const { t } = useTranslation();    
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const [isMobile] = useState(window.innerWidth <= 599);

    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".achievement-page");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    return (
        <main className={`achievement-page ${darkMode ? "dark" : "light"}`} onClick={handleMainContainerClick}>
            <Helmet>
                <title>{t("services.metaTitle")}</title>
                <meta name="description" content={t("services.metaContent")} />
            </Helmet>
            
            <div className="achievement-page-header">
                <img
                    src={data.headerImagePng}
                    alt={data.headerAlt}
                    className="achievement-page-header-image"
                    loading="eager"
                />
                <div className={`achievement-page-header-content ${darkMode ? "dark" : "light"}`}>
                    <h1>{t(data.title)}</h1>
                    <h2>{t(data.context)}</h2>
                    {data.contextExplain.map((context, index) => (
                        <p key={index}>{t(context)}</p>
                    ))}
                </div>
            </div>

            <section className="achievement-page-role">
                <h2>{t(data.role)}</h2>
                <ul>
                    {data.roleExplain.map((role, index) => (
                        <li key={index}>{t(role)}</li>
                    ))}
                </ul>
            </section>

            <section className="achievement-page-links">
                <h2>{t(data.linkText)}</h2>
                <div className="achievement-page-link-buttons">
                {data.link.map((link, index) => (
                    <a href={link} key={index} target="_blank" rel="noopener noreferrer">
                        <img src={data.imageLink[index]} alt={`Link ${index + 1}`} width={data.width} />
                    </a>
                ))}
                </div>
            </section>

            <section className="achievement-page-design">
                <h2>{t(data.design)}</h2>
                <div className="achievement-page-design-images">
                    {data.designImage.map((image, index) => (
                        <img
                            src={image}
                            alt={`Design ${index + 1}`}
                            key={index}
                            className="achievement-page-design-image"
                            height={data.height}
                        />
                    ))}
                </div>
            </section>
            </main>
    );
};

export default AchievementGenericPage;