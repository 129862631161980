import React, { useEffect, useState, useContext } from "react";
import {ThemeContext } from "./contexts/ThemeContext";
import { NavbarContext } from "./contexts/NavbarContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";


import "./styles/ArticleGenericPage.css";

const ArticleGenericPage = () => {
    //Constantes declaration
    const { articleId } = useParams();
    const [title, setTitle] = useState(null);
    const [author, setAuthor] = useState(null);
    const [content, setContent] = useState(null);
    const [headerImage, setHeaderImage] = useState(null);
    const { darkMode } = useContext(ThemeContext);
    const { navbarActive, setNavbarActive, scrollPosition } = useContext(NavbarContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile] = useState(window.innerWidth <= 599);

    //Get an Article
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/article/${articleId}`);
                const data = response.data;

                setTitle(data.title);          
                setAuthor({
                    name:data.name,
                    first_name: data.first_name,
                    picture: data.picture,
                    bio: data.bio,
                    instagram_url: data.instagram_url
                });
                setContent(data.content);
                setHeaderImage(data.header_image);
            } catch (error) {
                if (process.env.NODE_ENV !== "production") {
                    console.error("Error fetching article:", error);
                }
                if(error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        navigate("/404");
                    } else if (statusCode === 500) {
                        navigate("/500");
                    }
                }
            }
        };

        fetchArticle();
    }, [articleId, navigate]);

    //UseEffects and functions for mobile Navbar
    useEffect(() => {
        const preventTouchMove = (e) => e.preventDefault();

        if (navbarActive && isMobile) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", preventTouchMove);
        }

        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
        };
    }, [navbarActive, isMobile]);

    useEffect(() => {
        const mainContainer = document.querySelector(".article-page");
        if (navbarActive) {
            mainContainer.classList.add("menu-open");
            document.body.classList.add("menu-open");
        } else {
            mainContainer.classList.remove("menu-open");
            document.body.classList.remove("menu-open");
        }
    }, [navbarActive]);

    useEffect(() => {
        if (!navbarActive) {
            window.scrollTo(0, scrollPosition);
        }
    }, [navbarActive, scrollPosition]);

    const handleMainContainerClick = () => {
        if (navbarActive && isMobile) {
            setNavbarActive(false);
        }
    };

    if (!title || !author || !content) return <main className="article-page">{t("article.loading")}</main>

    //Render
    return (
        <main className="article-page" onClick={handleMainContainerClick}>
            <Helmet>
                <title>{/*t("article.metaTitle")*/}Article - Dreamer Agency</title>
                {/*<meta name="description" content={t("article.metaContent")} />*/}
            </Helmet>
            <div className="article-header-image" style={{ backgroundImage: `url(${headerImage})`}}>
                <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            </div>

            <div className={`article-content ${darkMode ? "dark" : "light"}`}>
                <article className="article-left-container">
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>   
                </article>
            </div>
        </main>
    );
}

export default ArticleGenericPage;