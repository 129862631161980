import headerDuoDate from '../images/png/background/duoDate.png';
import headerEasySchool from '../images/png/background/easySchool.png';
import headerAirbus from '../images/png/background/airbus.png';
import headerEtiopathe from '../images/png/background/etiopathe.png';
import headerLeou from '../images/png/background/leou.png';
import headerVelofute from '../images/png/background/velofute.png';

import apple from '../images/png/logo/apple.png';
import android from '../images/png/logo/android.png';

import velofute from "../images/png/achievements/velofuteLink.png";
import leou from "../images/png/achievements/leouLink.png";
import easySchool from "../images/png/achievements/easySchoolLink.png";
import etiopathe from "../images/png/achievements/etiopatheLink.png";

import airbus1 from "../images/png/achievements/airbus1.png";
import airbus2 from "../images/png/achievements/airbus2.png";
import airbus3 from "../images/png/achievements/airbus3.png";
import airbus4 from "../images/png/achievements/airbus4.png";

import velofute1 from "../images/png/achievements/velofute1.png";
import velofute2 from "../images/png/achievements/velofute2.png";
import velofute3 from "../images/png/achievements/velofute3.png";
import velofute4 from "../images/png/achievements/velofute4.png";

import easySchool1 from "../images/png/achievements/easySchool1.png";
import easySchool2 from "../images/png/achievements/easySchool2.png";
import easySchool3 from "../images/png/achievements/easySchool3.png";
import easySchool4 from "../images/png/achievements/easySchool4.png";

import leou1 from "../images/png/achievements/leou1.png";
import leou2 from "../images/png/achievements/leou2.png";
import leou3 from "../images/png/achievements/leou3.png";
import leou4 from "../images/png/achievements/leou4.png";

import etiopathe1 from "../images/png/achievements/etiopathe1.png";
import etiopathe2 from "../images/png/achievements/etiopathe2.png";
import etiopathe3 from "../images/png/achievements/etiopathe3.png";
import etiopathe4 from "../images/png/achievements/etiopathe4.png";

const pageAchievementData = {
    duoDate: {
        headerImagePng: headerDuoDate,
        title: "achievements.duoDate.title",
        context: "achievements.duoDate.context",
        contextExplain: [
            "achievements.duoDate.contextExplain"
        ],
        role: "achievements.duoDate.role",
        roleExplain: [
            "achievements.duoDate.roleExplain1",
            "achievements.duoDate.roleExplain2",
            "achievements.duoDate.roleExplain3",
            "achievements.duoDate.roleExplain4"
        ],
        linkText: "achievements.duoDate.linkText",
        link: [
            "https://play.google.com/store/apps/details?id=com.duo_date",
            "https://apps.apple.com/us/app/duo-date/id6499107057"
        ],
        imageLink: [
            android,
            apple
        ],
        design: "achievements.duoDate.design",
        designImage: [
            airbus1,
            airbus2,
            airbus3,
            airbus4
        ],
        width: "80px",
        height: "650px,"
    },
    easySchool: {
        headerImagePng: headerEasySchool,
        title: "achievements.easySchool.title",
        context: "achievements.easySchool.context",
        contextExplain: [
            "achievements.easySchool.contextExplain"
        ],
        role: "achievements.easySchool.role",
        roleExplain: [
            "achievements.easySchool.roleExplain1",
            "achievements.easySchool.roleExplain2"
        ],
        linkText: "achievements.easySchool.linkText",
        link: [
            "https://test2.dreameragency.fr"
        ],
        imageLink: [
            easySchool,
        ],
        design: "achievements.easySchool.design",
        designImage: [
            easySchool1,
            easySchool2,
            easySchool3,
            easySchool4
        ],
        width: "280px",
        height: "450px"
    },
    airbus: {
        headerImagePng: headerAirbus,
        title: "achievements.airbus.title",
        context: "achievements.airbus.context",
        contextExplain: [
            "achievements.airbus.contextExplain1",
            "achievements.airbus.contextExplain2"
        ],
        role: "achievements.airbus.role",
        roleExplain: [
            "achievements.airbus.roleExplain1",
            "achievements.airbus.roleExplain2",
            "achievements.airbus.roleExplain3"
        ],
        linkText: "achievements.airbus.linkText",
        link: [
            "https://play.google.com/store/apps/details?id=com.airbus_helicopters",
            "https://apps.apple.com/fr/app/guide-plan-de-vol/id6499146800"
        ],
        imageLink: [
            android,
            apple
        ],
        design: "achievements.airbus.design",
        designImage: [
            airbus1,
            airbus2,
            airbus3,
            airbus4
        ],
        width: "80px",
        height: "650px,"
    },
    etiopathe: {
        headerImagePng: headerEtiopathe,
        title: "achievements.etiopathe.title",
        context: "achievements.etiopathe.context",
        contextExplain: [
            "achievements.etiopathe.contextExplain"
        ],
        role: "achievements.etiopathe.role",
        roleExplain: [
            "achievements.etiopathe.roleExplain1",
            "achievements.etiopathe.roleExplain2"
        ],
        linkText: "achievements.etiopathe.linkText",
        link: [
            "https://cabinet-etiopathie-chevreuse.fr/"
        ],
        imageLink: [
            etiopathe,
        ],
        design: "achievements.etiopathe.design",
        designImage: [
            etiopathe1,
            etiopathe2,
            etiopathe3,
            etiopathe4
        ],
        width: "280px",
        height: "450px"
    },
    leou: {
        headerImagePng: headerLeou,
        title: "achievements.leou.title",
        context: "achievements.leou.context",
        contextExplain: [
            "achievements.leou.contextExplain"
        ],
        role: "achievements.leou.role",
        roleExplain: [
            "achievements.leou.roleExplain1",
        ],
        linkText: "achievements.leou.linkText",
        link: [
            "https://domaineduleou.fr/"
        ],
        imageLink: [
            leou,
        ],
        design: "achievements.leou.design",
        designImage: [
            leou1,
            leou2,
            leou3,
            leou4
        ],
        width: "280px",
        height: "450px"
    },
    velofute: {
        headerImagePng: headerVelofute,
        title: "achievements.velofute.title",
        context: "achievements.velofute.context",
        contextExplain: [
            "achievements.velofute.contextExplain1",
            "achievements.velofute.contextExplain2"
        ],
        role: "achievements.velofute.role",
        roleExplain: [
            "achievements.velofute.roleExplain1",
            "achievements.velofute.roleExplain2",
        ],
        linkText: "achievements.velofute.linkText",
        link: [
            "https://vf-fantasy-cyclisme.velofute.com/"
        ],
        imageLink: [
            velofute,
        ],
        design: "achievements.velofute.design",
        designImage: [
            velofute1,
            velofute2,
            velofute3,
            velofute4
        ],
        width: "280px",
        height: "450px"
    }
};

export default pageAchievementData;